<template>
  <page-view title="详情" left-arrow>
    <div class="article">
      <div class="article-header">
        <h1 class="article-title">{{xxbt}}</h1>
        <p class="article-time">
          <span>{{fbr}}</span>
          <span style="color: #245896;">{{xxly}}</span>
          <span>{{fbsj}}</span>
        </p>
      </div>
      <div v-if="tpdz" class="article-cover">
        <img class="article-cover-Img" :src="tpdz" />
      </div>
      <div class="article-content" v-html="xxnr" @click="imagePreview"></div>
    </div>
  </page-view>
</template>

<script>
import { ImagePreview } from 'vant';
import { getHlwFbxxByXxid } from '@/api/login'

export default {
  name: 'NewsDetail',
  props: {

  },
  data() {
    return {
      xxbt:'',
      tpdz: '',
      xxnr:'',
      fbr :'',
      xxly:'',
      fbsj:''
    }
  },
  methods: {
    fetchData(xxid) {
      getHlwFbxxByXxid(xxid).then(res => {
        if (res.status && res.status == 400) {
          throw new Error(res.message);
        }
        
        if (res.data && res.data.length) {  
          let data = res.data[0];
          this.xxbt = data.xxbt;
          this.tpdz = data.tpdz;
          this.xxnr = data.xxnr;
          this.fbr = data.fbr;
          this.xxly = data.xxly;
          this.fbsj = data.fbsj
        } else {
          throw new Error("信息不存在或已删除！");
        }
      }).catch(err => {
        this.$dialog.alert({
          title: "提示",
          message: "信息详情加载失败:"+ err.message
        }).then(() => {
          this.$router.back();
        });
      });
    },
    imagePreview(e){
      if(e.target.nodeName === 'IMG' || e.target.nodeName === 'img'){
        ImagePreview({
          images:[e.target.currentSrc]
        })
      }
    }
  },
  mounted() {
    let params = this.$route.params;
    if (params._id) {
      this.xxid = params._id;
      this.fetchData(params._id)
    }
  },
}
</script>

<style lang="scss" scoped>
  @import 'src/styles/variable.scss';

  .article {
    margin: 16px 0;
    padding: 16px 0;
  }
  .article-header {
    margin: 0 16px 24px 16px;
  }

  .article-title {
    margin-top: 0.25rem;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
  }
  .article-time /deep/{
    height: 30px;
    line-height: 30px;
    color: $color-gray-300;
    border-bottom: 1px solid #ececec;
    padding: 10px 0;
    margin-bottom: 0.20rem;
    span {
      margin-right: 10px;
      font-size: 12px;
      letter-spacing: 2px;
    }
  }
  .article-cover {
    margin: 24px 16px;
    border-radius: 4px;
    overflow: hidden;
  }
  .article-cover-Img {
    width: 100%;
    object-fit: cover;
  }
  .article-content /deep/{
    margin: 24px 16px;
    overflow-x: scroll;
    *  {
      max-width: 100%;
      box-sizing: border-box;
      word-wrap: break-word;
    }

    section {
      margin-bottom: 1.5em;
    }
    h1 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 0.9em;
      line-height: 1.4;
    }
    h2 {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 0.34em;
      line-height: 1.4;
    }
    h3 {
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 0.34em;
      line-height: 1.4;
    }
    p {
      margin: 0 0 0.8em;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>